const arrModalidad = [
    {
        id: 1,
        codigo: '02',
        nombre: 'Despacho a domicilio Santiago',
        tipo: 'despacho',
        costo: 3990,
        costo_retiro: 7980,
        direccion: 'Erasmo Escala 2220, Santiago, Región Metropolitana',
        horario: 'Lunes a Viernes de 8:00 a 19:30 y Sábado de 10:00 a 14:00 Hrs',
        nombre_suc: 'ERASMO ESCALA',
        region: 'Región Metropolitana',
        comuna: 'Santiago Centro',
        telefono: '600 300 1515',
        presku: 'new_presku02',
        ciudad: "Santiago",
        texto_descriptivo: 'Sólo contamos con despacho a las comunas de Cerrillos, Buin, Calera de Tango, Cerro navia, Colina, Conchali, El Bosque, Estacion Central, Huechuraba, Independencia, La Cisterna, La Florida, La Granja, La Reina, Lampa, Las Condes, Lo Barnechea, Lo Espejo, Lo Prado, Macul, Maipú, Ñuñoa, Paine, Pedro Aguirre Cerda, Peñalolen, Pirque, Providencia, Pudahuel, Puente Alto, Quilicura, Quinta Normal, Recoleta, Renca, San Bernardo, San Joaquin, San Jose de Maipo, San Miguel, San Ramon, Santiago, Vitacura.'
      },
      {
        id: 2,
        codigo: '10',
        nombre: 'Retiro en tienda Concepción',
        tipo: 'retiro',
        costo: 0,
        costo_retiro: 0,
        direccion: 'Angol 341, Concepción, Región del Biobío',
        horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
        nombre_suc: 'CONCEPCION',
        region: 'Región del Biobío',
        comuna: 'Concepción',
        telefono: '600 300 1515',
        presku: 'new_presku10',
        ciudad: "Concepcion",
        texto_descriptivo: ''
      },
      {
        id: 3,
        codigo: '10',
        nombre: 'Despacho a domicilio Concepción',
        tipo: 'despacho',
        costo: 3990,
        costo_retiro: 7980,
        direccion: 'Angol 341, Concepción, Región del Biobío',
        horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
        nombre_suc: 'CONCEPCION',
        region: 'Región del Biobío',
        comuna: 'Concepción',
        telefono: '600 300 1515',
        presku: 'new_presku10',
        ciudad: "Concepcion",
        texto_descriptivo: 'Sólo contamos con despacho a las comunas de Concepción, San Pedro de la Paz, Hualpén y Talcahuano.'
      },
      //BORRAR

      // {
      //   id: 4,
      //   codigo: '02',
      //   nombre: 'Retiro en tienda Erasmo Escala',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Erasmo Escala 2220, Santiago, Región Metropolitana',
      //   horario: 'Lunes a Viernes de 8:00 a 19:30 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'ERASMO ESCALA',
      //   region: 'Región Metropolitana',
      //   comuna: 'Santiago Centro',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku02',
      //   ciudad: "Santiago",
      //   texto_descriptivo: ''
      // },
      // {
      //   id: 5,
      //   codigo: '03',
      //   nombre: 'Retiro en tienda Providencia',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Av. Providencia 2411 Local 17',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'PROVIDENCIA',
      //   region: 'Región Metropolitana',
      //   comuna: 'Providencia',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku03',
      //   ciudad: "Santiago",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 6,
      //   codigo: '04',
      //   nombre: 'Retiro en tienda Puerta del Sol',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Puerta del Sol 36 Local 101',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'PUERTA DEL SOL',
      //   region: 'Región Metropolitana',
      //   comuna: 'Las Condes',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku04',
      //   ciudad: "Santiago",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 7,
      //   codigo: '05',
      //   nombre: 'Retiro en tienda La Florida',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Avenida Froilán Roa N°580 Local 14, segundo piso, PuertoCenter.',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'LA FLORIDA',
      //   region: 'Región Metropolitana',
      //   comuna: 'La Florida',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku05',
      //   ciudad: "Santiago",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 8,
      //   codigo: '06',
      //   nombre: 'Retiro en tienda Maipú',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Av. 5 de Abril 33, 2° Nivel Local 8 (Mall Pumay)',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'MAIPU',
      //   region: 'Región Metropolitana',
      //   comuna: 'Maipú',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku06',
      //   ciudad: "Santiago",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 9,
      //   codigo: '07',
      //   nombre: 'Retiro en tienda La Serena',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Av. Cristóbal Colón 352, Local 1',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'LA SERENA',
      //   region: 'Región de Coquimbo',
      //   comuna: 'La Serena',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku07',
      //   ciudad: "La Serena",
      //   texto_descriptivo: ''
      // },

      
      // {
      //   id: 10,
      //   codigo: '08',
      //   nombre: 'Retiro en tienda Curicó',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Calle Chacabuco 545 entre Estado y Merced.',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'CURICO',
      //   region: 'Región del Maule',
      //   comuna: 'Curicó',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku08',
      //   ciudad: "Curicó",
      //   texto_descriptivo: ''
      // },
            
      // {
      //   id: 11,
      //   codigo: '09',
      //   nombre: 'Retiro en tienda Talca',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Calle 7 Oriente 1264 entre calles 1 y 2 Norte',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'TALCA',
      //   region: 'Región del Maule',
      //   comuna: 'Talca',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku09',
      //   ciudad: "Talca",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 12,
      //   codigo: '11',
      //   nombre: 'Retiro en tienda Temuco',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Calle Claro Solar 780 Local 11',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'TEMUCO',
      //   region: 'Región de la Araucanía',
      //   comuna: 'Temuco',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku11',
      //   ciudad: "Temuco",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 13,
      //   codigo: '12',
      //   nombre: 'Retiro en tienda Antofagasta',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Calle Arturo Prat 272 Local 6-B',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'ANTOFAGASTA',
      //   region: 'Región de Antofagasta',
      //   comuna: 'Antofagasta',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku12',
      //   ciudad: "Antofagasta",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 14,
      //   codigo: '14',
      //   nombre: 'Retiro en tienda Lo Fontecilla',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Lo Fontecilla 101 local 2',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'LO FONTECILLA',
      //   region: 'Región Metropolitana',
      //   comuna: 'Las Condes',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku14',
      //   ciudad: "Santiago",
      //   texto_descriptivo: ''
      // },

      // {
      //   id: 15,
      //   codigo: '15',
      //   nombre: 'Retiro en tienda Iquique',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Calle Gorostiaga 451',
      //   horario: 'Lunes a Viernes 09:30 a 14:15 - 15:00 a 19:00 hrs. Sáb 10:00 a 14:00 hrs.',
      //   nombre_suc: 'IQUIQUE',
      //   region: 'Región de Tarapacá',
      //   comuna: 'Iquique',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku15',
      //   ciudad: "Iquique",
      //   texto_descriptivo: ''
      // },  

      // {
      //   id: 16,
      //   codigo: '16',
      //   nombre: 'Retiro en tienda La Cisterna',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Interior Estación Metro La Cisterna Linea 4A, Local 10',
      //   horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'LA CISTERNA',
      //   region: 'Región Metropolitana',
      //   comuna: 'La Cisterna',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku16',
      //   ciudad: "La Cisterna",
      //   texto_descriptivo: ''
      // }, 

      // {
      //   id: 17,
      //   codigo: '17',
      //   nombre: 'Retiro en tienda Punta Arenas',
      //   tipo: 'retiro',
      //   costo: 0,
      //   costo_retiro: 0,
      //   direccion: 'Avda Presidente Bulnes 315',
      //   horario: 'Lunes a Viernes de 10:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
      //   nombre_suc: 'PUNTA ARENAS',
      //   region: 'Región de Magallanes',
      //   comuna: 'Punta Arenas',
      //   telefono: '600 300 1515',
      //   presku: 'new_presku17',
      //   ciudad: 'Punta Arenas',
      //   texto_descriptivo: ''
      // }, 

]

export default arrModalidad;