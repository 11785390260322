const YAPP_URL = process.env.REACT_APP_YAPP_URL
const yappHeaders={
    'x-api-key' : process.env.REACT_APP_YAPP_APIKEY,
  }

  export const getBeneficiario = async (rutPaciente) => {
    // console.log("RUT del paciente:", rutPaciente);
  
    let data = {
      obtenido: false,
      datos: {},
    };
  
    const url = `${YAPP_URL}check_membership?beneficiary_identity_number=${rutPaciente}`;
    // console.log("URL:", url);
  
    try {
      const response = await fetch(url, {
        headers: yappHeaders,
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }
  
      const responseData = await response.json();
      // console.log("Datos obtenidos:", responseData);
  
      data = {
        obtenido: true,
        datos: responseData,
      };
    } catch (error) {
      console.log("Error en getBeneficiario:", error);
    }
  
    return data;
  };
  export const getConsultaProducto = async (kinf) => {
    let data = {
      obtenido: false,
      datos: {},
    };
  
    const url = `${YAPP_URL}coverage?sku=${kinf}`;
  
    try {
      const response = await fetch(url, {
        headers: yappHeaders,
      });
  
      // Si la respuesta no es OK, maneja el error antes de intentar leer el JSON
      if (!response.ok) {
        if (response.status === 400) {
          // Manejo específico para el error 400
          console.log("Solicitud incorrecta. Verifica el SKU o los parámetros.");
          data.datos = { mensaje: "Solicitud incorrecta. Verifica el SKU o los parámetros." };
        } else {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }
      } else {
        // Si la respuesta es exitosa, procesa el JSON normalmente
        const responseData = await response.json();
  
        if (responseData.status_code === 6) {
          console.log("Producto no encontrado:", responseData.detail);
          data = {
            obtenido: false,
            datos: { mensaje: responseData.detail },
          };
        } else {
          data = {
            obtenido: true,
            datos: { cobertura : responseData } ,
          };
        }
      }
  
      console.log("Datos obtenidos:", data);
  
    } catch (error) {
      console.log("Error en getConsultaProducto:", error.message);
    }
  
    return data;
  };
  // export const setCotizacionYapp = async (objYapp) => {
  //   console.log(objYapp)
  //   let data = {
  //     obtenido: false,
  //     datos: {},
  //   };
  //   const url =`${YAPP_URL}quote`;
  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         ...yappHeaders,
  //         'Content-Type': 'application/json', // Asegúrate de que el tipo de contenido sea JSON
  //       },
  //     body: JSON.stringify(objYapp)
  //   });
  // console.log(response);
  //     // Si la respuesta no es OK, maneja el error antes de intentar leer el JSON
  //     if (!response.ok) {
  //       if (response.status === 400) {
  //         console.log(response)
  //         // Manejo específico para el error 400
  //         // console.log("Solicitud incorrecta. Verifica el SKU o los parámetros.");
  //         data.datos = { mensaje: "Solicitud incorrecta. Verifica los datos enviados." };
  //       } else {
  //         throw new Error(`Error en la solicitud: ${response.status}`);
  //       }
  //     } else {
  //       // Si la respuesta es exitosa, procesa el JSON normalmente
  //       const responseData = await response.json();
  // console.log(responseData);
  //       if (responseData.status_code === 6) {
  //         console.log("Producto no encontrado:", responseData.detail);
  //         data = {
  //           obtenido: false,
  //           datos: { mensaje: responseData.detail },
  //         };
  //       } else {
  //         data = {
  //           obtenido: true,
  //           datos: { cobertura : responseData } ,
  //         };
  //       }
  //     }

  
  //   } catch (error) {
  //     console.log("Error en setCotizacionYapp:", error.message);
  //     console.log(error);
  //   }
  //   return data
  // }

  export const setCotizacionYapp = async (objYapp) => {
    console.log(objYapp);
    let data = {
      obtenido: false,
      datos: {},
    };
  
    const url = `${YAPP_URL}quote`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          ...yappHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(objYapp),
      });
  
      if (!response.ok) {
        try {
          const errorResponse = await response.json();
          data.datos = { mensaje: errorResponse.message || "Solicitud incorrecta. Verifica los datos enviados." };
        } catch (jsonError) {
          console.log("No se pudo leer el JSON del error:", jsonError);
          data.datos = { mensaje: "Error desconocido en la solicitud." };
        }
        return data;
      }
  
      const responseData = await response.json();
  
      if (responseData.status_code === 6) {
        console.log("Producto no encontrado:", responseData.detail);
        return {
          obtenido: false,
          datos: { mensaje: responseData.detail },
        };
      }
  
      return {
        obtenido: true,
        datos: { cobertura: responseData },
      };
    } catch (error) {
      console.log("Error en setCotizacionYapp:", error.message);
      return {
        obtenido: false,
        datos: { mensaje: `Error en la solicitud: ${error.message}` },
      };
    }
  };
  